import {
  EMPRESAS,
  PARTICULARES,
  EMPRESAS_SHORT,
  PARTICULARES_SHORT,
 } from '../constants/constants';

export const types = {
  GET_FOOTER_INFO: 'FOOTER/GET_FOOTER_INFO',
  SET_FOOTER_INFO: 'FOOTER/SET_FOOTER_INFO',
  SET_SEGMENT: 'FOOTER/SET_SEGMENT',
  SET_VISIBLE_AND_STATUS: 'FOOTER/SET_VISIBLE_AND_STATUS',
  SET_FOOTER_KIND: 'FOOTER/SET_FOOTER_KIND',
  /* SUBSCRIPTIONS */
  SUBSCRIBE_2_CONTEXT: 'FOOTER/SUBSCRIBE_2_CONTEXT',
  SUBSCRIBE_2_ENVIRONMENT: 'FOOTER/SUBSCRIBE_2_ENVIRONMENT',
  SUBSCRIBE_2_HISTORY: 'FOOTER/SUBSCRIBE_2_HISTORY',
};

export const initialState = {
  info: null, // we will work with this info
  status: '',
  visible: false,
  segment: '',
  footerKind: 'regular',
  /* just storing */
  gpInfo: null,
  empInfo: null,
};

const FooterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FOOTER_KIND:
      return {
        ...state,
        footerKind: action.kind,
      };
    case types.SET_SEGMENT: {
      const { gpInfo, empInfo } = state;
      const { segment } = action;
      let info = null;
      switch (segment) {
        case EMPRESAS:
        case EMPRESAS_SHORT:
          info = empInfo;
          break;
        case PARTICULARES:
        case PARTICULARES_SHORT:
        default:
          info = gpInfo;
      }
      return {
        ...state,
        info,
        segment: action.segment,
        visible: true,
        status: 'success',
      };
    }
    case types.SET_VISIBLE_AND_STATUS:
      return {
        ...state,
        status: action.data.status,
        visible: action.data.visible,
      };
    case types.SET_FOOTER_INFO: {
      const { gpInfo, empInfo } = action.data;
      return {
        ...state,
        gpInfo,
        empInfo,
      };
    }
    case types.SUBSCRIBE_2_CONTEXT:
    default:
      return state;
  }
};

export const actions = {
  getFooterInfo: data => ({ type: types.GET_FOOTER_INFO, data }),
  setFooterInfo: data => ({ type: types.SET_FOOTER_INFO, data }),
  setSegment: segment => ({ type: types.SET_SEGMENT, segment }),
  setVisibleAndStatus: data => ({ type: types.SET_VISIBLE_AND_STATUS, data }),
  setFooterKind: kind => ({ type: types.SET_FOOTER_KIND, kind }),
  /* SUBSCRIPTIONS */
  contextHandler: info => ({ type: types.SUBSCRIBE_2_CONTEXT, info }),
  environmentHandler: info => ({ type: types.SUBSCRIBE_2_ENVIRONMENT, info }),
  subscribe2History: history => ({
    type: types.SUBSCRIBE_2_HISTORY,
    history,
  }),
};

export default FooterReducer;
