import { all, takeLatest } from 'redux-saga/effects';

import { types as footerTypes } from '../reducers/footer';
import * as footerSagas from './footer';

export default function* rootSaga() {
  yield all([
    // get json info
    takeLatest(footerTypes.GET_FOOTER_INFO, footerSagas.getFooterInfoJson),
    // subscription to context
    takeLatest(footerTypes.SUBSCRIBE_2_CONTEXT, footerSagas.contextHandler),
    // subscription to header environment
    takeLatest(footerTypes.SUBSCRIBE_2_ENVIRONMENT, footerSagas.environmentHandler),
  ]);
}
