import React from 'react';

/**
 * Si algun dia se quiere hacer una maquetacion especifica para procesos de
 * compra habría que ponerla aquí.
 * @returns {null} no devuelve nada ya que no esta contemplado aún.
 */
const BuyProccessFooter = () => {
  return null
}

export default BuyProccessFooter;
