import React, { Component } from 'react';
import { connect } from 'react-redux';
import TaggedComponent from '@bit/gerenciatd.unifiedcomponents.tagged-component';
import { actions as footerActions } from './reducers/footer';
import RegularFooter from './containers/RegularFooter';
import { REGULAR_FOOTER, BUY_PROCESS } from './constants/constants';
import BuyProcessFooter from './containers/BuyProcessFooter';

class Main extends Component {
  componentDidMount = () => {
    const { getFooterInfo, environmentHandler, contextHandler } = this.props;
    getFooterInfo();

    window.nookInfoExchange.registerAsObserver('Context', `Footer-${Date.now()}`, (info) => {
      contextHandler(info);
    })

    window.nookInfoExchange.registerAsObserver('FooterEnvironment', `Header-${Date.now()}`, (info) => {
      environmentHandler(info)
    })
  }


  render() {
    const {
      infoFooter,
      status,
      visible,
      segment,
      footerKind,
    } = this.props;

    if (visible && infoFooter && segment) {
      return (
        <TaggedComponent
          id="Footer_Component"
          WaitingComponent={RegularFooter.WaitingComponent}
          status={status}
          componentName="Footer"
          taggingInfo={{
            componentContent: '',
            componentName: 'Footer_Component',
            componentPerso: '',
            componentProduct: '',
            componentPariation: 'all',
          }}
        >
          {footerKind && footerKind === REGULAR_FOOTER && (
            <RegularFooter data={infoFooter} segment={segment} />
          )}
          {footerKind && footerKind === BUY_PROCESS && (
            <BuyProcessFooter />
          )}
        </TaggedComponent>
      );
    }
    return null;
  }
}

/* DISPATCH TO REDUX FUNCTIONS */
const mapDispatchToProps = dispatch => ({
  getFooterInfo: () => dispatch(footerActions.getFooterInfo()),
  contextHandler: info => dispatch(footerActions.contextHandler(info)),
  subscribe2Environment: info => dispatch(footerActions.subscribe2Environment(info)),
  environmentHandler: info => dispatch(footerActions.environmentHandler(info)),
})
/* READS FROM REDUCERS */
const mapStateToProps = state => ({
  // footer setting
  segment: state.footer.segment,
  footerKind: state.footer.footerKind,
  infoFooter: state.footer.info,
  status: state.footer.status,
  visible: state.footer.visible,
})

export default connect(mapStateToProps, mapDispatchToProps)(Main);
