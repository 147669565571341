import {
  all,
  call,
  put,
  select,
} from 'redux-saga/effects';
import Sonda from '@bit/gerenciatd.utils.sonda';
import { findOutEnv } from '@bit/gerenciatd.utils.environment';
import { getFooterInfo, getFooterInfoEmp } from '@bit/gerenciatd.adabyron-cloud.miscellaneous';
import { getSegment } from '@bit/gerenciatd.utils.context';
import { actions as footerActions } from '../reducers/footer';
import { actions as contextActions } from '../reducers/context'
import { PARTICULARES, EMPRESAS } from '../constants/constants';
import FooterJsonDev from '../footer.json';
import FooterJsonDevEmp from '../footerEmp.json';

const mainSetup = require('../../config/mainSetup');

/* 1) ENVIRONMENT SUBSCRIPTION BEGINS */
export const contextFromStore = state => state.context.data;
/* SET JSON 2 USE */
export const getFooterFromStore = state => state.footer;
/* GETTING INITIAL INFO */
export const getInfoFromState = state => state.footer.info;

/**
 * Captura cada mensaje del canal de eventos creado para contexto,
 * y setea información en redux del segmento
 * @return {undefined}
 */
export function* contextHandler({ info }) {
  try {
    yield put(contextActions.setContext(info))
  } catch (error) {
    const context = yield select(contextFromStore);
    yield put(footerActions.setVisibleAndStatus({
      visible: false,
      status: 'error',
    }))
    const { projectId } = mainSetup;
    const sonda = new Sonda({
      eventName: 'subscribeToContext',
      context,
      error,
      origin: `${projectId}-SAGA:Footer`,
    });
    sonda.errorJs();
  }
}

/**
 * Subscripcion al canal de mensaje para cambiar los estados de la aplicacion
 * en funcion de los mensajes recibidos.
 * @return {undefined}
 */
export function* environmentHandler({ info }) {
  try {
    const noVisible = info.environment === 'app';

    let footerKind = '';
    switch (info.environment) {
      case 'app': footerKind = 'none'; break;
      case 'BuyProcess': footerKind = 'BuyProcess'; break;
      default: footerKind = 'regular'; break;
    }

    yield put(footerActions.setVisibleAndStatus({
      visible: !noVisible,
      status: 'success',
    }))
    yield put(footerActions.setFooterKind(footerKind));
  } catch (err) {
    const context = yield select(contextFromStore);
    yield put(footerActions.setVisibleAndStatus({
      visible: false,
      status: 'error',
    }))
    const { projectId } = mainSetup;
    const sonda = new Sonda({
      eventName: 'subscribeToFooterEnvironment',
      context,
      origin: `${projectId}-SAGA:Footer`,
    });
    sonda.errorJs(err.toString());
  }
}

export function* getJsonBySegment(segment, context) {
  let data = null;
  try {
    switch (segment) {
      case PARTICULARES: {
        const gpInfo = yield call(getFooterInfo, context);
        data = gpInfo.response;
      }
        break;
      default: {
        const empInfo = yield call(getFooterInfoEmp, context);
        data = empInfo.response;
      }
        break;
    }
  } catch (err) {
    // Sondado con las sondas de adabyron
    switch (segment) {
      case PARTICULARES: data = FooterJsonDev; break;
      default: data = FooterJsonDevEmp; break;
    }
  }
  return data;
}

/**
 * Obtiene el JSON de contribucion y configuracion del footer a traves de un EP
 * de adabyron y lo guarda en redux.
 * @return {undefined}
 */
export function* getFooterInfoJson() {
  let myOwnContext = yield select(contextFromStore);

  if (!myOwnContext || (myOwnContext && Object.keys(myOwnContext) === 0)) {
    myOwnContext = { environment: findOutEnv(), pageSegment: getSegment() }
  }

  try {
    const infoFromState = yield select(getFooterFromStore);

    yield put(footerActions.setVisibleAndStatus({
      visible: true,
      status: 'fetching',
    }))

    if (!infoFromState.gpInfo || !infoFromState.empInfo) {
      let allJsonInfo = null;

      if (myOwnContext.pageSegment === PARTICULARES) {
        allJsonInfo = yield all([
          getJsonBySegment(PARTICULARES, myOwnContext),
        ])
        yield put(footerActions.setFooterInfo({
          gpInfo: allJsonInfo[0],
        }));
      } else {
        allJsonInfo = yield all([
          getJsonBySegment(EMPRESAS, myOwnContext),
        ])
        yield put(footerActions.setFooterInfo({
          empInfo: allJsonInfo[0],
        }));
      }
    }

    yield put(footerActions.setSegment(myOwnContext.pageSegment));
  } catch (err) {
    yield put(footerActions.setFooterInfo({
      gpInfo: FooterJsonDev,
      empInfo: FooterJsonDevEmp,
    }));
    const { projectId } = mainSetup;
    const sonda = new Sonda({
      eventName: 'getInfoFromState',
      context: myOwnContext,
      origin: `${projectId}-SAGA:Footer`,
    });
    sonda.errorJs(err.toString());
  }
}
