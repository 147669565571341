import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import FooterReducer from './footer';
import ContextReducer from './context';

const rootReducers = history => combineReducers({
  router: connectRouter(history),
  footer: FooterReducer,
  context: ContextReducer,
});

export default rootReducers;
