import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Link } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import Main from './Main';
import mainSetup from '../config/mainSetup';
import store, { history } from './store';
import '../config/styles/unifiedStyles.scss';


/* checking incons styles */
const ICONS_CSS_FILENAME = 'https://prod.cms.col.movistar.es/static/fonts/icon/icons.min.css';
const loadCssIcons = () => {
  let isAlreadyLoaded = false;
  const ss = document.styleSheets;
  for (let i = 0, max = ss.length; i < max; i += 1) {
    if (ss[i].href === ICONS_CSS_FILENAME) {
      isAlreadyLoaded = true;
      break;
    }
  }
  if (!isAlreadyLoaded) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.async = 'async';
    link.defer = 'defer'
    link.href = ICONS_CSS_FILENAME;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
loadCssIcons();


ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="*" render={() => <Main history={history} />} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById(mainSetup.projectId)
);
