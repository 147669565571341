import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import TextInfo, { SIZE } from '@bit/gerenciatd.unifiedcomponents.text-info';
import CustomLink from '@bit/gerenciatd.unifiedcomponents.custom-link';
import { errorLog } from '@bit/gerenciatd.utils.log-utils';
import Skeleton from 'react-loading-skeleton';
import SvgIconLoader from '@bit/gerenciatd.unifiedicons.svg-icon-loader';
import CollapsableMenu from '../../components/CollapsableMenu';
// import Trustivity from '../../components/Trustivity';
import MainSetup from '../../../config/mainSetup';
import { EMPRESAS } from '../../constants/constants';
import './RegularFooter.scss';

const footerRoot = document.getElementById(MainSetup.projectId);

const COMPONENT_NAME = 'Footer';

const mainTaggingInfo = {
  componentName: COMPONENT_NAME,
  componentVariation: 'all',
  componentProduct: '',
  componentPerso: '',
};
/**
 * Componente para renderizar el Footer de la pagina.
 * @param {object} infoFooter Se recibe el JSON con la tribucion de todos los enlaces, iconos
 * y configuracion del footer.
 * @param {string} segment Se recibe el segmento al que pertenece el footer para cargar el footer
 * apropiado para GP o empresas.
 * @returns {object} Devuelve el jsx para pintar el Footer
 */
class Footer extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    // get ready collapsable mapping
    const mapping = this.getReadyCollapsableMapping(data);
    this.state = {
      data,
      mapping,
    };
  }

  getReadyCollapsableMapping = (data) => {
    const mapState = {};
    const { links } = data;
    const linksKeys = links ? Object.keys(links) : null;

    if (linksKeys) {
      linksKeys.forEach((key, index) => {
        const rowIndex = index;
        const currentRow = links[key];
        const currentRowItems = currentRow.length;
        let currentItemIndex = 0;
        while (currentItemIndex < currentRowItems) {
          const finalId = rowIndex * currentRowItems + (currentItemIndex + 1);
          if (!mapState[finalId]) {
            mapState[finalId] = 'hidden';
          }
          currentItemIndex += 1;
        }
      });
    }
    return mapState;
  };

  setCollapseMenuState = (id) => {
    const { mapping } = this.state;
    if (mapping && id && mapping[id]) {
      const currentValue = mapping[id];
      mapping[id] = currentValue === 'hidden' ? 'show' : 'hidden';
    }
    const newMapping = {
      ...mapping,
    };
    this.setState({
      mapping: newMapping,
    });
  };

  /* GENERATIONS ONCLICK BEGINS */
  /* global coreAiwi:true */
  handleAiwi = () => {
    try {
      if (coreAiwi) {
        coreAiwi.launchAiwi('footerMovistar');
      } else {
        errorLog('[Footer]: No se pudo lanzar el chat');
      }
    } catch (err) {
      errorLog('[Footer]: No se pudo lanzar el chat');
    }
  };

  createSpecificOnClick = (id) => {
    switch (id) {
      case 'footer-chat':
        return this.handleAiwi;
      // es necesario para que customlink se dibuje ya que no tiene link
      case 'cookies':
        return () => {};
      default:
        return null;
    }
  };

  createSpecificClassName = (id) => {
    switch (id) {
      case 'cookies':
        return 'optanon-toggle-display';
      default:
        return '';
    }
  };
  /* GENERATIONS ONCLICK ENDS */

  /* it will be a new component... */
  createItemLinkOnItemsContainer = (itemLink, key) => {
    const {
      id,
      title,
      svgId,
      label,
      iconColor,
      iconSize,
      link,
    } = itemLink;
    const { segment } = this.props;

    const onClick = this.createSpecificOnClick(id);
    const customLinkClassName = this.createSpecificClassName(id);
    const specialId = `itemLink-${id}-${label}${svgId}`;
    const lessSpecialId = `itemLink-${id}`;

    return (
      <div
        id={lessSpecialId}
        key={specialId}
        className={`itemLink ${key !== 'row-block-2' ? segment : ''} ${
          key === 'row-block-2' && segment === EMPRESAS ? 'empresas-blue' : ''
        }`}
      >
        {svgId && label && (
          <div className="svgAndLabel">
            <SvgIconLoader
              {...itemLink}
              size={iconSize}
              color={iconColor}
              segment={segment}
              id={svgId}
              className="regularIcon"
            />
            <TextInfo
              text={label}
              fontType="font-light"
              className={`regularLabel ${segment}`}
              segment={segment}
              color={iconColor}
              size={SIZE.CAPTION1}
            />
          </div>
        )}

        {svgId && !label && (
          <SvgIconLoader
            {...itemLink}
            size={iconSize}
            color={iconColor}
            segment={segment}
            id={svgId}
          />
        )}
        {!svgId && label && (
          <TextInfo
            text={label}
            fontType="font-light"
            className={`regularLabel ${segment}`}
            segment={segment}
            color={iconColor}
            size={SIZE.CAPTION1}
          />
        )}
        <CustomLink
          onClick={onClick}
          isBlockElement
          link={link}
          accInfo={{
            title: title || label || svgId,
            ariaLabel: title || label || svgId,
          }}
          taggingInfo={{
            ...mainTaggingInfo,
            componentContent: label,
          }}
          className={customLinkClassName}
          forceExternal
        />
      </div>
    );
  };

  createBalloonItemOnBalloonContainer = (ballonItem) => {
    const {
      title, svgId, label, link, iconSize, iconColor,
    } = ballonItem;
    const { segment } = this.props;

    let svgIcon = null;
    if (svgId) {
      svgIcon = (
        <SvgIconLoader
          {...ballonItem}
          color={iconColor}
          id={svgId}
          size={iconSize}
          className="ballonIcon"
          segment={segment}
        />
      );
    }

    const specialId = `itemBalloon-${label}${svgId}`;
    const lessSpecialId = `itemBalloon-${svgId}`;

    return (
      <div id={lessSpecialId} key={specialId} className="itemLink ballonItem">
        {!label && svgIcon && (
          <div className={`balloon ${segment}`}>{svgIcon}</div>
        )}
        <CustomLink
          forceExternal
          isBlockElement
          link={link}
          accInfo={{
            title: title || label || svgId,
            ariaLabel: title || label || svgId,
          }}
          taggingInfo={{
            ...mainTaggingInfo,
            componentContent: label || svgId,
          }}
        />
      </div>
    );
  };

  render() {
    const { segment } = this.props;
    const { data, mapping } = this.state;
    const { links, about } = data;
    const linksKeys = links ? Object.keys(links) : null;
    const aboutKeys = about ? Object.keys(about) : null;

    if (!segment) {
      return null;
    }

    return ReactDOM.createPortal(
      <div className="footer xux">
        <div className={`section ${segment}`}>
          {/* {segment !== EMPRESAS && (
            <div className="container footer_container">
              <Trustivity segment={segment} />
            </div>
          )} */}
          <div className="container footer_container">
            {linksKeys
              && linksKeys.map((key, index) => {
                // array of collapsable menu
                const rowIndex = index;
                const currentRow = links[key];
                return (
                  <div
                    key={`rowIndex-${rowIndex}-${index.toString()}`}
                    className="footer_row collapsable_menu_row"
                  >
                    {currentRow
                      && currentRow.map((currentMenu, indexito) => {                        
                        const collapseMenuid = (rowIndex * currentRow.length) + (indexito + 1);
                        const currentState = mapping[collapseMenuid];
                        const cssIdCollapsableMenu = `collapsableMenu_${rowIndex}_${
                          indexito + 1
                        } collapsableMenu--${currentState}`;
                        const { title } = currentMenu;
                        return (
                          <CollapsableMenu
                            {...currentMenu}
                            key={`collapsablemenu-${title}`}
                            className={cssIdCollapsableMenu}
                            id={collapseMenuid}
                            state={currentState}
                            segment={segment}
                            onClick={this.setCollapseMenuState}
                            title={title}
                            taggingInfo={mainTaggingInfo}
                          />
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={`section sectionrss ${segment}`}>
          <div className="container footer_container">
            {aboutKeys
              && aboutKeys.map((key) => {
                const currentAboutRow = about[key];
                const { items, balloonItems, name, title } = currentAboutRow;
                return (
                  <Fragment>
                    {title && (
                      <TextInfo
                        text={title}
                        align="center"
                        size={SIZE.TITLE5}
                        fontType="font-light"
                        color="grey"
                        segment={segment}
                      />
                    )}
                    <div
                      id={key}
                      key={`about-${key}`}
                      className={`footer_row_link ${name} ${segment}`}
                    >
                      {items && (
                        <div
                          className={`items_container ${
                            key !== 'row-block-4' ? segment : ''
                          }`}
                        >
                          {items.map(itemLink => this.createItemLinkOnItemsContainer(itemLink, key))}
                        </div>
                      )}
                      {balloonItems && (
                        <div className={`balloon_container ${segment}`}>
                          <div className="ballon_items_container">
                            {balloonItems.map(ballonItem => this.createBalloonItemOnBalloonContainer(ballonItem))}
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                  
                );
              })}
          </div>
        </div>
      </div>,
      footerRoot
    );
  }
}

Footer.propTypes = {
  segment: PropTypes.string.isRequired,
};

Footer.WaitingComponent = () => <Skeleton count={1} height={800} />;

export default Footer;
