import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgIconLoader from '@bit/gerenciatd.unifiedicons.svg-icon-loader';
import CustomLink from '@bit/gerenciatd.unifiedcomponents.custom-link';
import TextInfo, { SIZE } from '@bit/gerenciatd.unifiedcomponents.text-info';

import { EMPRESAS } from '../../constants/constants';
import './collapsablemenu.scss';

/**
 * Componente para renderizar el Footer de la pagina.
 * @param {string} key Clave univoca del menu colapsable.
 * @param {string} className Clase modificadora que si se recibe pisa o aplica estilos
 * propios al componente.
 * @param {string} id Identificador univoco del menu colapsable.
 * @param {string} state Estado de pintado.
 * @param {function} onClick Funcion que se ejecutará cuando se haga click.
 * @param {string} segment Identificador del segmento (GP o empresas).
 * @param {string} title Titulo del componente.
 * @param {object} taggingInfo Objeto para el taggeado de los botones del componente.
 * @returns {object} Devuelve el jsx para pintar el menu colapsable
 */
class CollapsableMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOnClick = () => {
    const { onClick, id } = this.props;
    if (onClick) {
      onClick(id);
    }
  };

  render() {
    const { state, taggingInfo, segment } = this.props;
    const { title, items, className } = this.props;
    const cheuronId = state === 'show' ? 'CheuronUp' : 'CheuronDown';
    const ownCss = className ? ` ${className}` : '';

    return (
      <div className={`collapsableMenu${ownCss}`}>
        {/* condition */}
        {title && (
          <div
            className={`collapsableMenu_head ${segment}`}
          >
            <div className={`collapsableMenu_head_container ${segment}`}>
              <TextInfo
                className="collapsableMenu_title"
                fontType="font-light"
                headingType={SIZE.TITLE4}
                level={4}
                text={title}
                segment={segment}
                color={segment === EMPRESAS ? 'grey6' : 'grey5'}
              />
              <SvgIconLoader
                size={20}
                id={cheuronId}
                className="cheuronTogle"
                segment={segment}
              />
              <CustomLink
                onClick={this.handleOnClick}
                className="titleLink"
                isBlockElement
                accInfo={{
                  title,
                  ariaLabel: title,
                }}
                taggingInfo={{
                  componentName: 'Footer',
                  componentContent: title,
                  componentProduct: '',
                  componentVariation: 'all',
                }}
              />
            </div>
          </div>
        )}
        <div className={`collapsableMenu_items ${segment}`}>
          {items
            && items.map((item) => {
              const {
                id, target, label, link, title: itemTitle,
              } = item;
              return (
                <div key={id} className="item">
                  <TextInfo
                    text={label}
                    fontType="font-light"
                    className="regularLabel"
                    segment={segment}
                    size={SIZE.CAPTION1}
                    color="blue"
                  />
                  <CustomLink
                    forceExternal
                    isBlockElement
                    target={target}
                    link={link}
                    accInfo={{
                      title: itemTitle || label,
                      ariaLabel: itemTitle || label,
                    }}
                    taggingInfo={{
                      ...taggingInfo,
                      componentContent: label,
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

CollapsableMenu.propTypes = {
  segment: PropTypes.string,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

CollapsableMenu.defaultProps = {
  segment: 'gp',
};

export default CollapsableMenu;
