module.exports = {
  projectId: 'footerRoot',
  codeSpliting: true,
  runServiceWorker: false,
  createNowDate: () => {
    const currentdate = new Date();
    let datetime = `${currentdate.getFullYear()}-${(currentdate.getMonth() + 1)}-${currentdate.getDate()}`;
    datetime = `${datetime}T${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`;
    return datetime;
  },
}
